<template>
    <div>
        <p class="text-secondary mb-1">
            <small>Organizer</small>
        </p>
        <div>
            <v-select class="review-details-selector form-control bg-light rounded-pill shadow-sm py-0"
                      v-bind:options="organizers"
                      v-bind:reduce="({ _id }) => _id"
                      v-bind:get-option-label="({ name, countryCode, phone }) => `${ name } (+${ countryCode } ${ phone })`"
                      v-model="organizer">
            </v-select>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
export default {
    name: "Organizer",
    components: {
        vSelect,
    },
    props: {
        value: {
            type: String,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        organizers () {
            return this.$store.getters["users/itemsByRole"]("organizer");
        },
        organizer: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            }
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
